<template>
  <div class="topicDetailView">
    <pageHead title="帖子详情" class="topicDetailView_head" />
    <div
      class="topicDetailView_content"
      id="topicDetailView_content"
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="handleScrollBottom"
    >
      <displayDetail
        ref="detail"
        @deleteArticle="handleDeleteArticle"
        v-if="JSON.stringify(articleDetail) != '{}'"
        :title="articleDetail.title"
        :userBasicVO="articleDetail.userBasicVO"
        :userAvatar="articleDetail.userBasicVO.avatar"
        :anonymous="articleDetail.isAnonymous"
        :userName="articleDetail.userBasicVO.username"
        :browseCount="articleDetail.articleStatisticVO.browseCount"
        :content="articleDetail.content"
        :articleLabels="articleDetail.articleLabels"
        :attList="articleDetail.attList"
        :imageList="articleDetail.imageList"
        :videoAddr="articleDetail.videoUrl"
        :createTime="articleDetail.createTime"
        :articleVoteVO="articleDetail.articleVoteVO"
        :topicList="articleDetail.topicTitles"
        :locationTips="articleDetail.locationTips"
        :articleId="articleDetail.articleId"
        :circleShow="circleShow"
        @originalTopicClick="handleOriginalTopicClick"
        @checkVote="handleChooseVote"
        @vote="handlePublicVote"
        @tagClick="handleTagClick"
        @userInfoClick="handleUserInfoClick"
        @follow="handleFollow"
        @handleNewActive="handleNewActive"
      />
      <!--      <tracking-error v-if="articleDetail.articleTrackVO" :relationVO="articleDetail.relationVO" :articleTrackVO="articleDetail.articleTrackVO" />-->
      <!--      <appraise v-if="showAppraise()" :articeData="articleDetail" />-->
      <!--  评论，赞，按时间热度排序等按钮-->
      <div class="topicDetailView_filterBar" v-if="isLoaded">
        <interactionTypeSelect
          :active-name.sync="interactionType"
          :options="interactionTypeSelectOptions"
          :data="interactionData"
          @change="handleInteractionTypeChange"
        />
        <sortKeySelect
          v-if="!hideRemove"
          v-model="sortKey"
          :options="sortKeySelectOptions"
          @input="changeSort"
        />
      </div>
      <div class="interactionList">
        <commentItem
          v-for="(item, index) in interactionListData"
          :key="item.commentId "
          v-bind="item"
          :sort-key="sortKey"
          :isAuthor="item.isTopEntityUser"
          :userAvatar="item.userBasicVO.avatar"
          :username="item.userBasicVO.username"
          :index="index"
          :postTotal="postTotal"
          :isFloor="true"
          :commenitem="item"
          :commentList="
            item.secondLevelCommentCount ? item.secondLevelComments : []
          "
          :total="
            item.secondLevelCommentCount ? item.secondLevelCommentCount : 0
          "
          :show-like="interactionType === 'comment'"
          :hideRemove="hideRemove"
          @loadMore="handleLoadMore"
          @click="handleReplyComment(item)"
          @replyTwoStair="handleReplyComment"
          @touch="handleCommentTouch({ item, index })"
          @touchend="handleCommentTouchend"
          @delete="handleDeleteComment({ item, index })"
          @deleteReply="handleDeleteReply"
          @like="handleCommentLike({ ...item, isLike: $event })"
          @userInfoClick="handleToUserDetail(item.userBasicVO.userId)"
        />
      </div>
      <div style="background-color: white">
        <van-divider
          contentPosition="center"
          style="margin: 0"
          v-if="interactionListData.length > 0 && !commentHasNextPage"
        >我是有底线的
        </van-divider
        >
      </div>
    </div>
    <toolbar
      :is-like="articleDetail.isThumbsUp"
      @inputFocus="handlerCommentTopic"
      @like="handleTopicLike"
      @share="handleShare"
    />
    <commentToolDialog
      v-model="editComment"
      :show-dialog.sync="showCommentToolDialog"
      :is-anonymous-comment.sync="isAnonymousComment"
      hide-img
      :hideRelation="hideRelation"
      @publish="handlePublishTopicComment"
      @delete="handleDeleteAtt"
      :attList="attList"
    />
    <replyDetail
      ref="replyDetail"
      :commentList="replyList"
      :showNoMore="!listQueryParams.hasNextPage && replyList.length >= 10"
      @reply="handleReplyComment"
      @loadMore="handleLoadReplyComment"
      @touch="handleCommentTouch($event)"
      @touchend="handleCommentTouchend"
      @delete="handleDeleteComment($event)"
      @userInfoClick="handleToUserDetail"
      @likeReply="likeReply"
    />
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import displayDetail from './components/displayDetail'
import commentItem from './components/commentItem'
import interactionTypeSelect from './components/interactionTypeSelect'
// import trackingError from "./components/trackingError";
// import appraise from "./components/appraise";
import sortKeySelect from '@/components/selectView'
import {
  detail as getArticleDetail,
  getByPage as getArticleByPage
} from '@/api/article'
import {addThumbsup, removeThumbsup, getThumbsupList} from '@/api/thumbsup'
import {
  addComment,
  getCommentList,
  removeComment,
  getCommentListSecond
} from '@/api/comment'
import {publishVote} from '@/api/votedetails'
import {attAdd, followRemove} from '@/api/att'
import toolbar from './components/toolbar'
import replyDetail from './components/replyDetail'
import commentToolDialog from '@/components/commentToolDialog'
import * as quoteType from '@/constant/quoteType'
import {getDictBiz} from '@/api/dict-biz'
import {mapGetters} from 'vuex'
import {setItem, getItem} from '@/utils/storage'
import {debounce} from "@/utils/debounce";

const SHARE = {label: '转发', key: 'share', valueKey: 'forwardCount'}
const COMMENT = {label: '评论', key: 'comment', valueKey: 'commentCount'}
const LIKE = {label: '赞', key: 'like', valueKey: 'thumbsUpCount'}
const ENTITY_TYPE_ARTICLE = 2
const ENTITY_TYPE_COMMENT = 1
export default {
  name: 'topicDetail',
  props: {
    id: [String, Number]
  },
  components: {
    pageHead,
    displayDetail,
    commentItem,
    sortKeySelect,
    interactionTypeSelect,
    toolbar,
    commentToolDialog,
    replyDetail
    // trackingError,
    // appraise
  },
  data () {
    return {
      postTotal: 0,
      isLoaded: false,
      editComment: '',
      // 文章详情
      articleDetail: {},
      // 评论列表
      commentList: [],
      commentHasNextPage: true,
      // 点赞列表
      thumbsupList: [],
      // 分享列表
      shareList: [],
      // 展示评论弹窗
      showCommentToolDialog: false,
      commentParams: {},
      isAnonymousComment: false,
      sortKey: 'time',
      interactionType: COMMENT.key,
      sortKeySelectOptions: [
        {label: '按时间排序', value: 'time'},
        {label: '按热度排序', value: 'like'}
      ],
      // todo 隐藏分享，后面添加 SHARE
      interactionTypeSelectOptions: [COMMENT, LIKE],
      activeName: 'share',
      pageScrollParams: {},
      voteChooseList: [],
      attList: [],
      isShowReply: false,
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        entityType: 2
      },
      replyList: [],
      hideRelation: false,
      hideRemove: false,
      circleShow: false
    }
  },
  watch: {
    id () {
      this.getArticleDetail()
      this.getCommentList()
      this.getThumbsupList()
    }
  },
  computed: {
    ...mapGetters(['articleTypes']),
    interactionData: {
      get () {
        const {articleStatisticVO} = this.articleDetail
        return articleStatisticVO || {}
      },
      set (val) {
        this.articleDetail.articleStatisticVO = val
      }
    },
    interactionListData () {
      const {interactionType, commentList, thumbsupList, shareList} = this
      switch (interactionType) {
        case SHARE.key:
          return shareList || []
        case COMMENT.key:
          return commentList || []
        case LIKE.key:
          return thumbsupList || []
        default:
          return []
      }
    }
  },
  created () {
    if (!this.interactionType) {
      this.interactionType = COMMENT.key
    }
  },
  mounted () {
    this.getArticleDetail()
    // this.handleInteractionTypeChange(this.interactionType)
    this.$set(this.pageScrollParams, COMMENT.key, 0)
    this.circleShow = this.$route.query.circleShow
    if (this.$route.query.selectMembers) {
      this.$route.query.selectMembers.split(',').forEach((item) => {
        let index = item.indexOf(':')
        const attName = item.substring(index + 1)
        this.attList.push({
          attId: item.substring(0, index),
          attName: attName
        })
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (from.name === 'organization') {
        vm.showCommentToolDialog = true
        const commentParams = getItem('commentParams')
        vm.commentParams = commentParams
        vm.editComment = commentParams.editComment
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    const {thumbsUpFlag, articleStatisticVO} = this.articleDetail
    const refreshData = {thumbsUpFlag, articleStatisticVO, id: this.id, articleDetail: this.articleDetail}
    setItem('refreshData', JSON.stringify(refreshData))
    if (to.name === 'organization') {
      setItem('commentParams', {
        ...this.commentParams,
        editComment: this.editComment
      })
    }
    next()
  },
  methods: {
    showAppraise () {
      // 只是展示原创和帖子
      let base =
        this.articleDetail.isVote === 0 && this.articleDetail.quoteType === 1
      let userId = this.$store.getters.user.user_id
      let isAuthor = `${this.articleDetail.userId}` === userId
      let isAddedAppriase =
        this.articleDetail.articleEvaluationVOs &&
        this.articleDetail.articleEvaluationVOs.length > 0
      if (isAddedAppriase) {
        return base && isAddedAppriase
      }
      if (isAuthor) {
        return (
          base &&
          isAuthor &&
          this.articleDetail.articleStatisticVO.commentCount > 0
        )
      }
    },
    async handleLoadMore (id) {
      this.replyList = []
      this.listQueryParams = {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        entityType: 2
      }
      this.listQueryParams.entityId = id
      await this.handleLoadReplyComment()
      this.$refs.replyDetail._data.isOpen = true
    },
    async handleLoadReplyComment () {
      let listQueryParams = this.listQueryParams
      if (listQueryParams.hasNextPage) {
        const res = await getCommentListSecond(this.listQueryParams)

        // res.records.forEach((item) => {
        //   let count = resData.filter(
        //     (innerItem) => innerItem.commentId === item.commentId
        //   )
        //   if (count.length === 0) {
        //     resData.push(item)
        //   }
        // })

        this.replyList = res.records.map(this.commentItemDataFormat)
        listQueryParams.hasNextPage = res.current < res.pages
        listQueryParams.pageNum = listQueryParams.pageNum + 1

        return this.replyList
      }
    },
    // 切换评论，点赞
    handleInteractionTypeChange: debounce(function (type) {
      this.hideRemove = type !== COMMENT.key
      switch (type) {
        case SHARE.key:
          if (this.shareList.length === 0) {
            this.$set(this.pageScrollParams, type, 1)
            this.getShareList()
          }
          break
        case COMMENT.key:
          if (this.commentList.length === 0) {
            this.$set(this.pageScrollParams, type, 1)
            this.getCommentList()
          }
          break
        case LIKE.key:
          if (this.thumbsupList.length === 0) {
            this.$set(this.pageScrollParams, type, 1)
            this.getThumbsupList(1, true)
          }
          break
      }
      // this.$set(this.pageScrollParams, type, 1)
    }, 200),
    // 滚动到底部
    handleScrollBottom () {
      let {interactionType} = this
      if (!interactionType) {
        return false
      }
      this.pageScrollParams[interactionType] += 1
      switch (interactionType) {
        case SHARE.key:
          this.getShareList(this.pageScrollParams[interactionType])
          break
        case COMMENT.key:
          this.getCommentList(this.pageScrollParams[interactionType])
          break
        case LIKE.key:
          if (!this.pageScrollParams[interactionType]) {
            return false
          }
          this.getThumbsupList(this.pageScrollParams[interactionType])
          break
      }
    },
    /**
     * 分享
     */
    handleShare () {
      const {id: quoteId} = this
      this.$router.push({name: 'createTopic', query: {quoteId}})
    },
    /**
     * 评论点赞切换排序
     */
    changeSort () {
      this.commentList = []
      this.commentHasNextPage = true
      this.handleInteractionTypeChange(COMMENT.key)
    },
    /**
     * 跳转用户详情页
     */
    handleToUserDetail (userId) {
      this.$router.push({name: 'userInfoDetail', params: {userId}})
    },
    /**
     * 评论文章
     */
    handlerCommentTopic () {
      this.hideRelation = false
      const {id: entityId} = this
      this.commentParams = {
        entityId,
        entityType: 2
      }
      this.showCommentToolDialog = true
    },
    /**
     * 获取文章详情
     * @returns {Promise<void>}
     */
    async getArticleDetail () {
      const {id} = this
      const params = {articleId: id}
      this.articleDetail = await getArticleDetail(params)
      this.isLoaded = true
      let types = []
      // 从首页跳转过来，直接打开评论回复
      if (this.$route.query.showComponent) {
        this.showCommentToolDialog = true
        const entityType = this.$route.query.entityType
        const entityId = this.$route.query.id
        this.commentParams['entityId'] = entityId
        this.commentParams['entityType'] = entityType ? entityType : 2
      }
      if (this.articleTypes.length) {
        types = this.articleTypes
      } else {
        getDictBiz({code: 'article_category_type'}).then((res) => {
          types = res.map((v, i) => {
            return {
              ...v,
              chosen: i === 0
            }
          })
          this.$store.commit('common/setArticleTypes', types)
          const articleDetail = this.articleDetail
          const target = types.find(
            (v) => v.categoryId === articleDetail.categoryId
          )
          if (target) {
            this.$set(this.articleDetail, 'typeLabel', target.dictValue)
          }
        })
      }
    },
    /**
     * 评论点赞/取消点赞
     */
    async handleCommentLike (item) {
      let isThumbsUpFlag = item.isLike
      let entityId = item.commentId
      if (item.isLike.isReply) {
        isThumbsUpFlag = item.isLike.isThumbsUp
        entityId = item.isLike.commentId
      }
      await this.submitLike({isThumbsUpFlag, entityId, entityType: 2})

      if (item.isLike.isReply) {
        this.commentList.forEach((commentitem) => {
          if (commentitem.commentId === item.commentId) {
            item.secondLevelComments.forEach((value) => {
              if (value.commentId == entityId) {
                value.isThumbsUp = !value.isThumbsUp
                let count = value.isThumbsUp
                  ? value.thumbsUpCount + 1
                  : value.thumbsUpCount - 1
                value.thumbsUpCount = count
              }
            })
          }
        })
      } else {
        this.commentList = this.commentList.map((item) => {
          const {commentId: id, thumbsUpCount} = item
          if (id === entityId) {
            return this.commentItemDataFormat({
              ...item,
              isThumbsUp: !isThumbsUpFlag,
              thumbsUpCount: isThumbsUpFlag
                ? thumbsUpCount - 1
                : thumbsUpCount + 1
            })
          }
          return item
        })
      }
    },
    /**
     * 点赞/取消点赞
     * @returns {Promise<void>}
     */
    async handleTopicLike () {
      const {isThumbsUp: isThumbsUpFlag} = this.articleDetail
      const {thumbsUpCount} = this.interactionData
      await this.submitLike({
        isThumbsUpFlag,
        entityId: this.id,
        entityType: 1
      })
      this.articleDetail.isThumbsUp = !isThumbsUpFlag
      this.interactionData = {
        ...this.interactionData,
        thumbsUpCount: isThumbsUpFlag ? thumbsUpCount - 1 : thumbsUpCount + 1
      }
    },
    /**
     * 提交点赞/取消点赞
     */
    async submitLike ({isThumbsUpFlag, entityId, entityType}) {
      if (isThumbsUpFlag) {
        await removeThumbsup({entityId, entityType})
        this.getThumbsupList(1, true)
      } else {
        await addThumbsup({entityId, entityType})
        this.getThumbsupList()
      }
    },
    /**
     * 回复评论
     */
    handleReplyComment (item) {
      this.hideRelation = true
      if (this.interactionType !== 'comment') {
        return
      }
      let entityId = item.entityId ? item.entityId : item.commentId

      let replyToCommentId = (item.type === 2 || item.type === 3) ? item.commentId : null
      this.commentParams = {
        entityId,
        entityType: ENTITY_TYPE_COMMENT,
        replyToCommentId
      }
      // if (toId !== -1) {
      //   this.commentParams.toId = toId
      // }
      this.editComment = ''
      this.showCommentToolDialog = true
    },
    /**
     * 提交评论
     */
    async handlePublishTopicComment () {
      const {
        editComment: content,
        commentParams,
        isAnonymousComment: isAnonymous,
        attList
      } = this
      if (!content.trim()) {
        this.$notify({
          message: '评论内容不能为空',
          type: 'danger'
        })
        return
      }
      let contentReg = /[&><"']/g
      if (contentReg.test(content)) {
        this.$notify({
          message: '不能包含特殊字符&><"\'等，请重新输入',
          type: 'danger'
        })
        return
      }
      const atUserIds = attList.map((item) => {
        return item.attId
      })
      let commentData = {}
      commentData = await addComment({
        content,
        isAnonymous,
        ...commentParams,
        atUserIds
      })
      this.attList = []
      // 成功后回调修改展示数据
      switch (commentParams.entityType) {
        case 2:
          this.commentList.unshift(this.commentItemDataFormat(commentData))
          this.interactionData.commentCount += 1
          this.postTotal = this.postTotal + 1
          break
        case 1:
          this.replyList.unshift(this.commentItemDataFormat(commentData))
          this.commentList = this.commentList.map((item) => {
            if (item.commentId !== commentParams.entityId) {
              return item
            }
            let total = item.secondLevelCommentCount || 0
            let records = []
            if (item.secondLevelComments.length) {
              records = [
                this.commentItemDataFormat(commentData),
                ...item.secondLevelComments
              ]
            } else {
              records = [this.commentItemDataFormat(commentData)]
            }
            return {
              ...item,
              secondLevelCommentCount: total + 1,
              secondLevelComments: records
            }
          })
          break
      }
      this.editComment = ''
      this.showCommentToolDialog = false
      this.$route.query.selectMembers = ''
    },
    /**
     * 获取评论列表
     */
    async getCommentList (pageNum = 1) {
      if (!this.commentHasNextPage) {
        return;
      }
      const {id: entityId, sortKey} = this
      let sortType = ''
      if (sortKey === 'like') {
        sortType = 2
      } else {
        sortType = 1
      }
      const {total, records, current, pages} = await getCommentList({
        entityId,
        pageNum,
        entityType: ENTITY_TYPE_ARTICLE,
        sortType
      })
      this.commentHasNextPage = current < pages
      this.postTotal = total
      let newRecords = records.map(this.commentItemDataFormat).filter(innerItem => {
        let exists = this.commentList.filter(item => item.commentId === innerItem.commentId)
        if (exists.length === 0) {
          return innerItem;
        }
      })
      this.commentList = this.commentList.concat(newRecords)
    },
    handleNewActive (item) {
      this.articleDetail.articleVoteVO = item
    },
    /**
     * 格式化 commentItemData
     */
    commentItemDataFormat (val) {
      let {
        content: contentText,
        userName: username,
        isThumbsUp: isLike,
        thumbsUpCount: likeNum,
        toName,
        userBasicVO: {avatar: userAvatar},
        userBasicVO: {userId: userId},
        isAnonymous: anonymous,
        commentTime: createTime,
        atUsers: attList,
        secondLevelComments: commentList,
        commentId: id
      } = val
      if (!username) {
        username = this.$store.getters.user.user_name
      }
      return {
        ...val,
        commentList,
        userId,
        createTime,
        contentText,
        username,
        isLike,
        likeNum,
        toName,
        userAvatar,
        anonymous,
        attList,
        id
      }
    },
    /**
     * 获取点赞列表
     * @returns {Promise<void>}
     */
    async getThumbsupList (pageNum = 1, refreshAll = false) {
      const {id: articleId} = this
      const {records} = await getThumbsupList({
        pageNum,
        articleId,
        entityType: ENTITY_TYPE_ARTICLE,
        pageSize: 10
      })
      let newList = records.map(this.thumbsupFormat)
      let allList = refreshAll ? [] : this.thumbsupList
      newList.filter((item) => {
        let tempList = allList.filter(
          (innerItem) =>
            innerItem.userBasicVO.userId === item.userBasicVO.userId
        )
        if (tempList.length === 0) {
          allList.push(item)
        }
      })
      this.thumbsupList = allList
    },
    /**
     * 格式化点赞数据
     * @param val
     * @returns {*&{contentText: string, username}}
     */
    thumbsupFormat (val) {
      const {
        userBasicVO: {avatar: userAvatar},
        userBasicVO: {username: username}
      } = val
      return {...val, username, contentText: '赞', userAvatar}
    },
    handleOriginalTopicClick ({id}) {
      this.$router.push({query: {id}})
    },
    /**
     * 获取转发列表
     * @param pageNum
     * @returns {Promise<void>}
     */
    async getShareList (pageNum = 1) {
      const {id: quoteId} = this
      const {records} = await getArticleByPage({
        quoteType: quoteType.FORWARD,
        quoteId,
        pageNum
      })
      this.shareList = this.shareList.concat(
        records.map((item) => {
          const {userName: username, content: contentText} = item
          return {...item, username, contentText}
        })
      )
    },
    handleChooseVote (data) {
      const {pattern} = this.articleDetail
      let {voteChooseList} = this
      if (pattern === 0) {
        this.articleDetail.voteList.forEach((item) => {
          if (item.id === data.id) {
            item.voteFlag = !item.voteFlag
            voteChooseList = [item.id]
            return
          }
          item.voteFlag = false
        })
      } else {
        this.articleDetail.voteList.forEach((item) => {
          if (item.id === data.id) {
            item.voteFlag = !item.voteFlag
            if (item.voteFlag) {
              voteChooseList.push(item.id)
            } else {
              voteChooseList.splice(voteChooseList.indexOf(item.id), 1)
            }
          }
        })
      }
      this.voteChooseList = voteChooseList
    },
    handlePublicVote () {
      if (this.voteChooseList.length > 0) {
        publishVote(this.voteChooseList).then((res) => {
          this.articleDetail.voteFlag = true
          this.articleDetail.voteCount += 1
          this.articleDetail.voteList = res.voteList
        })
      } else {
        this.$toast.fail({
          message: '请选择后再投票！',
          position: 'top'
        })
      }
    },
    // 本人删除帖子后重新调取帖子详情接口【改为返回上一个，如果返回的是首页，刷新列表】
    handleDeleteArticle () {
      setItem('refreshFlag', 'true')
      this.$router.go(-1)
    },
    handleDeleteAtt (index) {
      this.attList.splice(index, 1)
    },
    handleTagClick (tag) {
      this.$router.push({name: 'searchTag', query: {tag: tag}})
    },
    handleUserInfoClick () {
      let userId = this.articleDetail.userId
      if (!userId) {
        userId = this.articleDetail.userBasicVO.userId
      }
      this.$router.push({
        name: 'userInfoDetail',
        params: {userId: userId}
      })
    },
    handleCommentTouch ({item, index} = {}) {
      clearTimeout(this.Loop)
      const {userId} = item
      const {user_id} = this.$store.getters.user
      if (
        this.interactionType === 'comment' &&
        parseInt(userId) === parseInt(user_id)
      ) {
        if (parseInt(userId) === parseInt(user_id)) {
          this.Loop = setTimeout(() => {
            this.handleDeleteComment({item, index})
          }, 1000)
        }
      }
    },
    handleCommentTouchend () {
      clearTimeout(this.Loop)
    },
    handleDeleteComment ({item, index} = {}) {
      const {content} = item
      this.$dialog
        .confirm({
          title: '确认删除该评论吗',
          message: content
        })
        .then(async () => {
          const {commentId, entityId} = item
          await removeComment(commentId)
          if (this.$refs.replyDetail._data.isOpen) {
            this.replyList.splice(index, 1)
            if (index <= 1) {
              const i = this.commentList.findIndex((item) => {
                return item.commentId == entityId
              })
              if (i !== -1) {
                this.listQueryParams = {
                  pageNum: 1,
                  pageSize: 10,
                  hasNextPage: true,
                  entityId
                }
                this.handleLoadReplyComment().then((res) => {
                  const arr = res.slice(0, 2)
                  this.commentList[i].secondLevelComments = arr
                  this.commentList[i].secondLevelCommentCount =
                    this.commentList[i].secondLevelCommentCount - 1
                })
              }
            }
          } else {
            this.commentList.splice(index, 1)
            this.interactionData.commentCount -= 1
            this.postTotal = this.postTotal - 1
          }
        })
        .catch(() => {
          console.log('cancel')
        })
    },
    handleDeleteReply (item) {
      this.$dialog
        .confirm({
          title: '确认删除该评论吗',
          message: item.content
        })
        .then(async () => {
          await removeComment(item.commentId)

          let i = this.commentList.findIndex((value) => {
            return value.commentId === item.entityId
          })

          if (i != -1) {
            let entityId = item.entityId
            this.listQueryParams = {
              pageNum: 1,
              pageSize: 10,
              hasNextPage: true,
              entityId
            }
            const res = await this.handleLoadReplyComment()
            const arr = res.slice(0, 2)
            this.commentList[i].secondLevelComments = arr
            this.commentList[i].secondLevelCommentCount =
              this.commentList[i].secondLevelCommentCount - 1
          }
        })
        .catch(() => {
          console.log('cancel')
        })
    },
    async handleFollow () {
      const {id, myFollow} = this.articleDetail
      if (myFollow) {
        await followRemove(id)
        this.articleDetail.commonFollowCount--
      } else {
        await attAdd(id)
        this.articleDetail.commonFollowCount++
      }
      this.articleDetail.myFollow = !myFollow
    },
    likeReply (value) {
      this.replyList = this.replyList.map((item) => {
        const {commentId: id, thumbsUpCount} = item
        if (id === value.entityId) {
          return this.commentItemDataFormat({
            ...item,
            isThumbsUp: !value.isThumbsUpFlag,
            thumbsUpCount: value.isThumbsUpFlag
              ? thumbsUpCount - 1
              : thumbsUpCount + 1
          })
        }
        return item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topicDetailView {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_head {
    border-bottom: solid 1px #cccccc;
  }

  &_filterBar {
    /*margin-top: 10px;*/
    background-color: white;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
  }

  &_content {
    flex: 1;
    overflow-y: auto;

    .interactionList {
      padding: 20px;
      background: white;
      position: relative;
    }
  }
}
</style>
